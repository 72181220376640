import { GameDesignIcon } from "../../components/layout/icons/GameDesignIcon";
import { GameProgrammingIcon } from "../../components/layout/icons/GameProgrammingIcon";
import { ArtProductionIcon } from "../../components/layout/icons/ArtProductionIcon";
import { ContinuousDevelopmentIcon } from "../../components/layout/icons/ContinuousDevelopmentIcon";
import { MinimapData } from "../../components/layout/minimap/Minimap";
import { ServiceData } from "../../components/sections/gameDevelopement/Service";
import { WorkData } from "../../components/sections/index/OurWork";
import React from "react";

export const gameDevPageData: GameDevPageData = {
    metaPageTitle: "Game Development Services & Process - iDream Interactive",
    metaKeywords: "stages of game development, full sail game development, difference between game design and game development, game development process, game development",
    metaDescription: "Find out what's involved with creating a video game from scratch. Follow along our process and how we can help you bring your game to reality.",
    title: "Game Development",
    services: [
        {
            icon: <GameDesignIcon fill="#080a2e" />,
            title: "Game Design",
            description: "Learn more about how we can help you during the initial phases of the game design process.  Everything from the research we do, to idea development, economy & level design, a customized design document and marketing strategy.",
            link: "/game-development/game-design",
        },
        {
            icon: <GameProgrammingIcon fill="#080a2e" />,
            title: "Game Programming",
            description: "Need someone to create your dream game?  We can help with initial prototypes, creating custom tooling and even porting your game from another technology.  When it's time to release your title, we've got you covered with extensive quality assurance testing and a killer launch day plan.",
            link: "/game-development/game-programming",
        },
        {
            icon: <ArtProductionIcon fill="#080a2e" />,
            title: "Art Production",
            description: "If visuals aren't your thing, that's okay.  It's ours. We can create everything from concept designs to in-game artwork & assets.  Let us wireframe and polish your user interfaces and make sure your users love the experience.",
            link: "/game-development/art-production",
        },
        {
            icon: <ContinuousDevelopmentIcon fill="#080a2e" />,
            title: "Continuous Development",
            description: "Have you released your game but need some additional support?  Let us do the heavy lifting.  We can build out new game features, manage your customer support and even build out special events with live operations.",
            link: "/game-development/continuous-development",
        }
    ],
    workData: {
        title: "Our Work",
        buttonLabel: "View Work",
        work: [
            {
                image: {
                    name: "rrfbCard",
                    src: "",
                    alt: "Recipe Rescue: Fruit Blast",
                },
                url: "recipe-rescue-fruit-blast"
            },
            {
                image: {
                    name: "sssCard",
                    src: "",
                    alt: "Superstar Slots",
                },
                url: "superstar-slots"
            },
        ]
    },
    minimapData: [
        {
            index: 1,
            data: [
                {
                    label: "Research",
                    title: "Research",
                    description: (<>
                        <p>Once we have a solid understanding of the game idea with concept drawings, we dive deep into research.</p>
                        <p>Are there other games out there with a similar idea? It is our job to determine if the idea already exists and to let our clients know.</p>
                        <p>We work with our clients to refine their ideas to better differentiate it from others.</p>
                    </>),
                },
                {
                    label: "Game Design Document",
                    title: "Game Design Document",
                    description: (<>
                        <p>A simple, easy to read document that explains your product before it is built. This document answers key questions such as how the game is played, how it will be monetized, the look and feel and general overview of the game.</p>
                        <p>A GDD maps out the concepts that will be used as a guide to create prototypes. It will not delve into such things as what technology to use behind the scenes.</p>
                    </>),
                },
                {
                    label: "Prototyping",
                    title: "Prototyping",
                    description: (<>
                        <p>We believe that prototyping is one of the most exciting parts of the development process. One of the biggest questions we are trying to answer when prototyping is: Is it fun to play? If we find that it is not fun to play, it allows us to further research and make improvements or change the idea completely.</p>
                    </>),
                },
            ]
        },
        {
            index: 2,
            data: [
                {
                    label: "Design Game Assets",
                    title: "Design Game Assets",
                    description: (<>
                        <p>We first start by creating wireframes. These wireframes are visual guides that outline layouts and different screens.</p>
                        <p>Once approved, we add placeholder images to provide context.  These images will later be replaced with final artwork, but this will give the developers and stakeholders a better idea of what’s to come.</p>
                        <p>Developers will use wireframes to prototype functionality. During this process, our designers will flesh out the final artwork.</p>
                    </>),
                },
                {
                    label: "Implement Functionality",
                    title: "Implement Functionality",
                    description: (<>
                        <p>Developers begin by creating a framework for the games systems that are detailed in the Game Design Document. Early stages of development are focused on creating a sustainable, scalable framework to support the game for years to come.</p>
                        <p>Once a good framework has been put in place, Developers will begin creating the visual layouts and screens of the game based on wireframes from the design team. These features will be continuously tested by our quality assurance team and bounce back and forth between development and testing until a stable build is reached.</p>
                    </>),
                },
                {
                    label: "QA Testing",
                    title: "QA Testing",
                    description: (<>
                        <p>Our quality assurance team is responsible for finding inaccuracies in the product and reporting it back to the developers.</p>
                        <p>Some of the methods they use to find bugs and inaccuracies include designing and running through test cases, completing checklists, performing regression testing, and load testing.</p>
                    </>),
                },
            ]
        },
        {
            index: 3,
            data: [
                {
                    label: "Release Candidate",
                    title: "Release Candidate",
                    description: (<>
                        <p>Once all of our teams are confident that the build is stable, we deem it our release candidate.</p>
                        <p>A release candidate is the final stable version of the game which is a stopping point for new feature development.</p>
                    </>),
                },
                {
                    label: "Marketing Strategy",
                    title: "Marketing Strategy",
                    description: (<>
                        <p>We believe that your marketing is a key component to the success of your game.  The sooner we can start the conversation about how to get your title in front of your target audience, the better.</p>
                        <p>An updated website, social media posts or maybe even Twitch streamers. We have many recommendations of how we can help.</p>
                    </>),
                },
                {
                    label: "Launch Day",
                    title: "Launch Day",
                    description: (<>
                        <p>The day your game is released to the public is a big deal.  It is the grand opening of your title. Let us help you bring the balloons and help you cut the cord to a successful launch.</p>
                    </>),
                },
            ]
        },
        {
            index: 4,
            data: [
                {
                    label: "Support",
                    title: "Support",
                    description: (<>
                        <p>Enabling the right support channel for your game is so important.   We can help you build a support team and automated methods to capture details about what your users were doing when they experienced a problem.</p>
                    </>),
                },
                {
                    label: "Live Operations",
                    title: "Live Operations",
                    description: (<>
                        <p>Many new games add additional content throughout the year that could be themed around events or holidays.  Add snow in the winter or bunnies in the spring.</p>
                        <p>We plan for live operation updates as we build our games so that it speeds up the development life cycle.</p>
                    </>),
                },
                {
                    label: "New Features",
                    title: "New Features",
                    description: (<>
                        <p>Lastly, plan forward and listen to your community for additional feature suggestions.  Although this may seem like the last stage of the process, it is not.  We thrive on long lasting relationships with our clients.  When you are ready for the next big update, we are here to help.</p>
                    </>),
                },
            ]
        },
    ]
}

type GameDevPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    services: ServiceData[],
    workData: WorkData,
    minimapData: MinimapData[],
}