import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import { Navigation } from "../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { GameDevelopmentIcon } from "../components/layout/icons/GameDevelopmentIcon";
import Img from "gatsby-image";
import { HeaderImageContainer } from "../components/layout/HeaderImageContainer";
import { Container } from "../components/layout/Container";
import { Service } from "../components/sections/gameDevelopement/Service";
import { ContainerColour, DiagonalContainer, ContainerSize } from "../components/layout/DiagonalContainer";
import { WorkData, OurWork } from "../components/sections/index/OurWork";
import { MinimapScroller, MinimapTheme } from "../components/layout/minimap/MinimapScroller";
import { gameDevPageData } from "../content/pageData/GameDevelopmentData";
import { BlobOne } from "../components/layout/blobs/BlobOne";
import { BlobThree } from "../components/layout/blobs/BlobThree";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        airplaneMobile: {
            childImageSharp: any;
        }
        airplane: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = gameDevPageData.title;
    const icon = <GameDevelopmentIcon fill="#ffffff" />;
    const airplaneMobile = data.airplaneMobile.childImageSharp.fluid;
    const airplane = data.airplane.childImageSharp.fluid;

    const airplaneSources = [
        airplaneMobile,
        {
            ...airplane,
            media: `(min-width: 768px)`,
        }
    ]
    const workData: WorkData = { work: gameDevPageData.workData.work };

    for (const item of workData.work) {
        item.image.src = data[item.image.name].childImageSharp.fluid;
    }


    const renderServices = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < gameDevPageData.services.length; ++i) {
            const service = gameDevPageData.services[i];
            const element = <Service
                key={`game-dev-service-${service.title}-i`}
                service={service}
            />
            elements.push(element);
        }

        return elements;
    }

    return (
        <Layout
            pageTitle={gameDevPageData.metaPageTitle}
            keywords={gameDevPageData.metaKeywords}
            description={gameDevPageData.metaDescription}
            url={"game-development"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.MEDIUM}>
                <HeaderInfo
                    className="game-development-header"
                    title={title}
                    icon={icon}
                />
            </ParallaxHeader>
            <HeaderImageContainer>
                <Img className="airplane" fluid={airplaneSources} alt={`Airplane`} />
            </HeaderImageContainer>
            <Container className="game-dev-services">
                <div className="blob-container">
                    <BlobOne fill={"#080a2e08"} />
                    <BlobThree fill={"#080a2e08"} />
                </div>
                <h2 className="title">Services</h2>
                <div className="game-dev-services-container">
                    {renderServices()}
                </div>
            </Container>
            <DiagonalContainer size={ContainerSize.LARGE} colour={ContainerColour.ORANGE} className="minimap-diagonal-container" hasRips={false}>
                <h2 className="title">Our<br></br>Process</h2>
                <MinimapScroller minimaps={gameDevPageData.minimapData} theme={MinimapTheme.DARK} />
            </DiagonalContainer>
            <OurWork workData={{ title: gameDevPageData.workData.title, buttonLabel: gameDevPageData.workData.buttonLabel, work: workData.work }} />
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        airplaneMobile: file(relativePath: { eq: "assets/images/game-development/airplane_small.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        airplane: file(relativePath: { eq: "assets/images/game-development/airplane.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        rrfbCard: file(relativePath: { eq: "assets/images/work/rrfb_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sssCard: file(relativePath: { eq: "assets/images/work/sss_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`